<template>
    <div v-if="candidateDetails.freelancer">
        <b-card
            class="section-container p-2"
            no-body
        >
            <b-card-header>
                <h2 class="card-title">{{ i18nT(`My Resume`) }}</h2>
                <b-dropdown
                    v-if="iCan('applications', 'write')"
                    no-caret
                    right
                    toggle-class="p-0"
                    variant="link"
                >
                    <template #button-content>
                        <feather-icon
                            class="align-middle text-body"
                            icon="MoreVerticalIcon"
                            size="16"
                        />
                    </template>
                    <b-dropdown-item
                        :to="{ name: 'ca-settings' }"
                    >
                        <span class="align-middle ml-50">{{ i18nT(`Edit my CV`) }}</span>
                    </b-dropdown-item>
                </b-dropdown>
            </b-card-header>
            <b-card-body>
                <b-row>
                    <b-col md="6" sm="12">

                        <!-- Profile image -->
                        <b-row>
                            <b-col cols="12">
                                <div class="d-flex w-100">
                                    <b-avatar
                                        :src="candidateDetails.freelancer.ImageUrl"
                                        :text="(candidateDetails.freelancer.FirstName ? candidateDetails.freelancer.FirstName.charAt(0) : '') + (candidateDetails.freelancer.LastName ? candidateDetails.freelancer.LastName.charAt(0) : '')"
                                        class="badge-light-danger mr-1"
                                        size="4rem"
                                    >
                                    </b-avatar>
                                    <div class="flex-grow-1">
                                        <h1>{{ candidateDetails.freelancer.Label }}</h1>
                                    </div>
                                </div>
                            </b-col>

                        </b-row>

                        <!-- About -->
                        <b-row>
                            <b-col cols="6" v-if="candidateDetails.candidate">
                                <p class="mb-0 mt-50">{{ candidateDetails.candidate.Position }}</p>
                                <p class="mb-0 mt-50">
                                    <small v-if="candidateDetails.candidate.Nationality" class="text-muted">
                                        <font-awesome-icon :icon="['fas', 'globe']"  style="margin-right: 2px;"/> {{ i18nT(candidateDetails.candidate.Nationality) | capFirst }} |
                                    </small>

                                    <small v-if="candidateDetails.candidate.Gender" class="text-muted">
                                        <font-awesome-icon :icon="['fas', 'venus-mars']"  style="margin-right: 2px;"/>{{ i18nT(candidateDetails.candidate.Gender) | capFirst }} |
                                    </small>

                                    <small v-if="candidateDetails.candidate.BirthDate" class="text-muted">
                                        <font-awesome-icon :icon="['fas', 'birthday-cake']" style="margin-right: 2px;"/> {{ candidateDetails.candidate.BirthDate | date }}
                                    </small>
                                </p>

                            </b-col>

                            <!-- CONTACT DATA -->
                            <b-col cols="6">
                                <div class="info-item mt-50">
                                    <feather-icon
                                        icon="PhoneIcon"
                                        size="16"
                                        class="mr-1"
                                    />
                                    <span> {{ candidateDetails.freelancer.Phones || i18nT(`Not available`) }}</span>
                                </div>
                                <div class="info-item mt-50">
                                    <feather-icon
                                        icon="MailIcon"
                                        size="16"
                                        class="mr-1"
                                    />
                                    <span>
                                    <b-link
                                        :href="'mailto:'+candidateDetails.freelancer.Email"
                                        class="position-relative"
                                    >{{ candidateDetails.freelancer.Email }}</b-link>
                                </span>
                                </div>
                            </b-col>

                        </b-row>

                        <b-row>
                            <b-col cols="12">
                                <!-- BIO / SUMMARY -->
                                <cv-summary :candidateDetails="candidateDetails"></cv-summary>
                            </b-col>

                            <b-col cols="12">
                                <b-row>
                                    <b-col md="6" sm="12">
                                        <cv-resume :candidateDetails="candidateDetails"></cv-resume>
                                    </b-col>
                                    <b-col md="6" sm="12">
                                        <cv-cover-letter :candidateDetails="candidateDetails"></cv-cover-letter>
                                    </b-col>
                                </b-row>
                            </b-col>


                            <b-col cols="12">
                                <!-- Skills -->
                                <cv-skills :candidateDetails="candidateDetails"></cv-skills>
                            </b-col>

                            <b-col cols="12">
                                <!-- Languages -->
                                <cv-languages :candidate-details="candidateDetails"></cv-languages>
                            </b-col>

                            <!-- CERTIFICATES & REFERENCES -->
                            <b-col cols="12">
                                <b-row>
                                    <b-col md="6" sm="12">
                                        <!-- CERTIFICATES -->
                                        <cv-certificates :candidateDetails="candidateDetails"></cv-certificates>
                                    </b-col>

                                    <b-col md="6" sm="12">
                                        <!-- References -->
                                        <cv-references :candidateDetails="candidateDetails"></cv-references>
                                    </b-col>
                                </b-row>

                            </b-col>

                            <b-col cols="12">
                                <b-row>
                                    <b-col md="6" sm="12">
                                        <!-- INTERESTS -->
                                        <cv-interests :candidateDetails="candidateDetails"></cv-interests>
                                    </b-col>
                                    <!-- Social Links -->
                                    <b-col md="6" sm="12">
                                        <cv-social-links :candidateDetails="candidateDetails"></cv-social-links>
                                    </b-col>
                                </b-row>

                                <cv-relocate-remote :candidateDetails="candidateDetails"></cv-relocate-remote>

                            </b-col>

                        </b-row>

                    </b-col>

                    <b-col md="6" sm="12">
                        <!-- Experience -->
                        <cv-work-xp :candidateDetails="candidateDetails"></cv-work-xp>

                        <!-- Education -->
                        <cv-education :candidateDetails="candidateDetails"></cv-education>
                    </b-col>
                </b-row>
            </b-card-body>

        </b-card>
    </div>
</template>

<script>

import {
    BAvatar,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BDropdown,
    BDropdownItem,
    //BProgress,
    //BDropdownDivider,
    BLink,
} from 'bootstrap-vue'

// import Educations from "@/views/recruiting/Candidates/Educations.vue";
// import Jobs from "@/views/recruiting/Candidates/Jobs.vue";

import CvSummary from "@/views/recruiting/CVComponents/CvSummary.vue";
import CvWorkXp from "@/views/recruiting/CVComponents/CvWorkXp.vue";
import CvEducation from "@/views/recruiting/CVComponents/CvEducation.vue";
import CvInterests from "@/views/recruiting/CVComponents/CvInterests.vue";
import CvSocialLinks from "@/views/recruiting/CVComponents/CvSocialLinks.vue";
import CvRelocateRemote from "@/views/recruiting/CVComponents/CvRelocateRemote.vue";
import CvReferences from "@/views/recruiting/CVComponents/CvReferences.vue";
import CvCertificates from "@/views/recruiting/CVComponents/CvCertificates.vue";
import CvLanguages from "@/views/recruiting/CVComponents/CvLanguages.vue";
import CvSkills from "@/views/recruiting/CVComponents/CvSkills.vue";
import CvResume from "@/views/recruiting/CVComponents/CvResume.vue";
import CvCoverLetter from "@/views/recruiting/CVComponents/CvCoverLetter.vue";


export default {
    name: "Resume",
    components: {
        BAvatar,
        BRow,
        BCol,
        BCard,
        BCardHeader,
        BCardBody,
        //BProgress,
        // Educations,
        // Jobs,
        BDropdown,
        BDropdownItem,
        BLink,

        CvCoverLetter,
        CvResume,
        CvEducation,
        CvWorkXp,
        CvSummary,
        CvSkills,
        CvLanguages,
        CvCertificates,
        CvReferences,
        CvInterests,
        CvSocialLinks,
        CvRelocateRemote,

    },
    created() {
        this.$http.get(
            `candidates/profile?full=1`
        ).then(({data}) => {
            this.candidateDetails = data.data
            if(this.candidateDetails.freelancer && !this.candidateDetails.candidate){
                this.candidateDetails.candidate = this.candidateDetails.freelancer;
            }
            this.skillOptions = data.data.skill_levels
            this.languageOptions = data.data.language_levels
            this.cvAvailability = this.candidateDetails.freelancer.PublicStatus === 'PUBLIC'
            this.publicLink = `${window.location.protocol}//${window.location.host}/resume/${data.data.freelancer.FirstName}-${data.data.freelancer.LastName}_${data.data.freelancer.Id}`
        })
        this.$http.get(
            `system/countries`
        ).then(({data}) => {
            this.countries = data.data
        })
    },
    data(){
        return {
            candidateDetails: {},
            skillOptions: [],
            languageOptions: [],
            cvAvailability: false,
            publicLink: '',
        }


    },
    methods: {
        navToEdit() {
            this.$router.push({
                name: 'ca-settings',
                params: {
                    id: this.profile.Id
                }
            })
        },
    }

}
</script>

<style scoped>
.sk-social-link {

}
</style>
